import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation ($username: String!, $pass: String!) {
    login(username: $username, pass: $pass) {
      token
      error
    }
  }
`;
