import { useMutation } from "@apollo/client";
import LockIcon from "@mui/icons-material/Lock";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Copyright } from "./copyright";
import { LOGIN } from "./login.query";

const theme = createTheme();

export const LoginPage = () => {
  const [loginMutation, { data }] = useMutation(LOGIN);
  let navigate = useNavigate();

  // Always make sure to clean up token.
  useEffect(() => {
    window.localStorage.clear();
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    loginMutation({
      variables: {
        username: formData.get("login"),
        pass: formData.get("password"),
      },
    }).then((res) => {
      if (res.data.login.token) {
        localStorage.setItem("token", res.data.login.token);
        navigate("/");
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#f8c400" }}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Garnek
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            {data && data.error !== "" && (
              <Alert sx={{ mt: 1 }} severity="error">
                Nieprawidłowy email lub hasło
              </Alert>
            )}

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
              Zaloguj
            </Button>
          </Box>
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};
