import TakeoutDiningIcon from "@mui/icons-material/TakeoutDining";
import { Button } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Outlet, useNavigate } from "react-router-dom";
import { NavBar } from "./NavBar";

const drawerWidth: number = 220;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

declare module "@mui/material/styles" {
  interface Palette {
    ochre: Palette["primary"];
  }

  interface PaletteOptions {
    ochre?: PaletteOptions["primary"];
  }
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  minHeight: "45px !important",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    boxSizing: "border-box",
    borderRight: 0,
  },
}));

const mdTheme = createTheme({
  palette: {
    ochre: {
      main: "#fff7af",
      light: "#E9DB5D",
      dark: "#A29415",
      contrastText: "#242105",
    },
  },
  typography: {
    fontSize: 12,
  },
});

function MainContent() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar
            sx={{
              pr: "24px",
              minHeight: "45px !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          ></Toolbar>
        </AppBar>
        <Drawer variant="permanent">
          <Toolbar
            sx={{
              display: "flex",
              minHeight: "45px !important",
              backgroundColor: "black",
              justifyContent: "flex-start",
              px: [1],
            }}
          >
            <Typography component="h1" variant="h3" color="inherit" noWrap>
              <Button
                sx={{
                  color: "white",
                  fontSize: "1rem",
                  p: 1,
                  pl: "12px",
                  alignItems: "center",
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                startIcon={<TakeoutDiningIcon />}
                onClick={() => navigate("/")}
              >
                GARNEK
              </Button>
            </Typography>
          </Toolbar>
          <Divider />
          <NavBar />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            flexGrow: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <Toolbar
            sx={{
              minHeight: "45px !important",
            }}
          />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Main() {
  return <MainContent />;
}
