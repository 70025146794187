import { Route, Routes } from "react-router-dom";
import { LoginPage } from "../login/login.page";
import { ProductsPage } from "../products/products-page/products.page";
import App from "./App";

export const AppRoutes = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="/" element={<App />}>
      <Route path="/" element={<ProductsPage />} />
      <Route path="products" element={<ProductsPage />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <h1>404 Brak strony lub brak uprawnień</h1>
          </main>
        }
      />
    </Route>
  </Routes>
);
