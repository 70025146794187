import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HomeIcon from "@mui/icons-material/Home";
import { Divider, List, styled } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { NavLink } from "react-router-dom";

const LinkSC = styled(NavLink)({
  textDecoration: "none",
  color: "inherit",
  "&.active > *": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
});

export const NavBar = () => {
  // const { data } = useQuery(ORDERED_ITEMS_TODO_COUNT);
  // const user = useQuery(CURRENT_USER_QUERY);

  // if (user.loading) {
  //   return <CircularProgress />;
  // }

  // if (
  //   user.data.currentUser === null ||
  //   localStorage.getItem("token") === null
  // ) {
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <React.Fragment>
      <List component="nav">
        <LinkSC to="/">
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Produkty" />
          </ListItemButton>
        </LinkSC>
        <LinkSC to="ingredients">
          <ListItemButton>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Baza surowca" />
          </ListItemButton>
        </LinkSC>
        <Divider sx={{ my: 2 }} />
        <LinkSC to="settings">
          <ListItemButton>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Ustawienia" />
          </ListItemButton>
        </LinkSC>
      </List>
    </React.Fragment>
  );
};
