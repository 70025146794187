import { Navigate } from "react-router-dom";
import "./App.css";
import Main from "./Main";

function App() {
  const token = window.localStorage.getItem("token");

  if (!token) {
    return <Navigate to={"/login"} replace />;
  }

  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
